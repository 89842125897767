
import Navbar from './components/navbar/navbar'
import Login from './components/login/login'
import Datatable from './elements/table/datatable'
import Chart from './elements/chart/chart'
import SidebarDropdown from './elements/dropdown/sidebar'
import Form from './elements/form/form'
import Code from './elements/code/code'
import Tab from './elements/tab/tab'
import Messaging from './components/messaging/messaging'
import Toggle from './elements/toggle/toggle'
import FloatingButton from './elements/floating/button'

import Theming from './components/theming/theming'
import Demo from './components/demo'